var site = site || {};
var Model = Model || {};
var google = google || {};
var generic = generic || {};
var Mustache = Mustache || {};
site.prodStoreCheck = site.prodStoreCheck || {};

site.InvisApp = (function() {
  var that = this;
  this.dom = {};
  this.skuId = null;
  this.inv_product = null;
  this.doors = [];
  this.count = 0;
  this.map = null;
  this.markers = [];
  this.infoWindow = null;
  this.searchPanelData = null;
  this.inv_vis_city = null;
  this.errors = [];

  $(document).on('invis:init', function(e, args) {
    that.handleInit(args);
  });

  $(document).on('DoorsInventory:query:success', function(e, args) {
    that.handleDoorsInvResp(args);
    // set form defaults if search successfull
    if (typeof args.params !== 'undefined') {
      that.inv_vis_city = args.params[0].city;
    }
    that.hideLoadingIcon();
  });

  $(document).on('MapApi:init:complete', function() {
    that.renderMap();
    that.renderStores();
  });

  $(document).on('DoorsInventory:query:failure', function(e, args) {
    that.handleDoorsInvFail(args);
    that.hideLoadingIcon();
  });

  $(document).on('invis:searchPanel:rendered', function(e, args) {
    that.handleSearchPanelRendered(args);
  });

  $(document).on('invis:mainPanel:rendered', function(e, args) {
    that.handleMainPanelRendered(args);
  });

  /*
   * Handles init event. Called from outside the app.
   */
  this.handleInit = function(args) {
    that.getNodes();
    if (args) {
      that.skuId = args.skuId;
      that.inv_product = args.product;
    }
    that.initTemplates();

    // check that this product isn't excluded by PRODUCT_ID, DEFAULT_CAT_ID, or MISC_FLAGS
    var isValidProd = that.checkProdFlags(args.product)
                      && that.checkProdCats(args.product)
                      && that.checkProdIds(args.product);
    if (isValidProd) {
      that.resetInputValue();
    } else { // this product / category / misc flag is excluded so remove inv nodes
      that.dom.$app.each(function() {
        $(this).remove();
      });
    }
  };
  /*
    * Convience method to compare a product's MISC_FLAG to the MISC_FLAG set in the CMS.
    * The CMS adds the MISC_FLAGs to hide in the module array site.prodStoreCheck.hideBtnMiscFlags.
    * If the MISC_FLAG of the product matches any of the flags in the array, it will fail.
  */
  this.checkProdFlags = function(data) {
    if (!data || !site.prodStoreCheck.hideBtnMiscFlags) {
      return true;
    }
    var isValid = true;
    var flagsArray = site.prodStoreCheck.hideBtnMiscFlags;
    for (var x = 0; x < flagsArray.length; x++) {
      if (data.MISC_FLAG.toString() === flagsArray[x].toString()) {
        isValid = false;
      }
    }
    return isValid;
  };

  /*
    * Convience method to compare a product's DEFAULT_CAT_ID to the hide_button_cats set in the CMS.
    * The CMS adds the hide_button_cats to hide in the module array site.prodStoreCheck.hideBtnCats.
    * If the hide_button_cats of the product matches any of the flags in the array, it will fail.
  */
  this.checkProdCats = function(data) {
    if (!data || !site.prodStoreCheck.hideBtnCats) {
      return true;
    }
    var isValid = true;
    var catsArray = site.prodStoreCheck.hideBtnCats.toString().split(',');
    for (var x = 0; x < catsArray.length; x++) {
      if (data.DEFAULT_CAT_ID.toString() === catsArray[x].toString().trim()) {
        isValid = false;
      }
    }
    return isValid;
  };

  /*
    * Convience method to compare a product's PRODUCT_ID to the PRODUCT_IDs set in the CMS.
    * The CMS adds the PRODUCT_IDs to hide in the module array site.prodStoreCheck.hideBtnProds.
    * If the PRODUCT_ID of the product matches any of the flags in the array, it will fail.
  */
  this.checkProdIds = function(data) {
    if (!data || !site.prodStoreCheck.hideBtnProds) {
      return true;
    }
    var isValid = true;
    var prodsArray = site.prodStoreCheck.hideBtnProds.toString().split(',');
    for (var x = 0; x < prodsArray.length; x++) {
      if (data.PRODUCT_ID.toString() === prodsArray[x].toString().trim()) {
        isValid = false;
      }
    }
    return isValid;
  };
  /*
   * Handles success response to doorsandskustatus RPC (called in model/doors_inventory.js).
   * Stores doors inventory response in private vars
   */
  this.handleDoorsInvResp = function(response) {
    var isSearchPanelActive = that.dom.$searchPanel.length > 0;
    that.doors = response.sorted;
    that.count = response.count;
    if (isSearchPanelActive) {
      var args = {doors: that.doors, count: response.count};
      this.renderSearchPanel(args);
    }

    that.renderStartPanelContent();
  };

  /*
   * Handles failure response to doorsandskustatus RPC (called in model/doors_inventory.js).
   * argument Object
   * example {errors: [{key: 'err_no_results'}]}
   */
  this.handleDoorsInvFail = function(args) {
    if (args.errors && args.errors.length) {
      that.renderMessages(args);
      that.renderResults();
    }
    that.renderStartPanelContent();
  };

  /*
   * Handles event fired when tempaltes become available.
   * Launches initial query to doorsandskustatus.
   */
  this.handleTemplatesInserted = function() {
    this.renderStartPanel();
    this.renderStartPanelContent(); // Required to render the element that the user can click on to open the locator popup
  };

  /*
   * Inserts unbound start panel into DOM, pulls CMS data from the rendered element
   */
  this.renderStartPanel = function() {
    var tmpl = Mustache.render($('script[path="invis/start_panel"]').html());
    this.dom.$app.html(tmpl);
    this.dom.$startPanel = this.dom.$app.find('.js-invis-start-panel');
    this.safetyStock = this.dom.$startPanel.data('safety-stock');
    this.region_id = this.dom.$startPanel.data('region-id') || null;
    this.language_id = this.dom.$startPanel.data('language-id') || null;
  };

  /*
   * Inserts start panel into DOM, pulls CMS data from the rendered element.
   * binds data & events to UI
   */
  this.renderStartPanelContent = function() {
    var panelData = {};
    panelData.door = that.findBestDoor();
    if (panelData.door) {
      panelData.hasLocalDoors = 1;
      if (panelData.door.skus_onhand) {
        panelData.hasInventory = 1;
        if (panelData.door.skus_onhand[that.skuId] && panelData.door.skus_onhand[that.skuId]['is_available']) {
          panelData.hasAvailable = 1;
        }
      }
    }
    var tmpl = Mustache.render($('script[path="invis/start_panel_content"]').html(), panelData);
    var $oldContent = this.dom.$startPanel.find('.js-invis-start-content');
    if ($oldContent.length) {
      $oldContent.remove();
    }
    this.dom.$startPanel.append(tmpl);

    if (panelData.door) {
      $('.js-link-open-store', that.dom.$app)
        .attr('href', that.getGoogleMapsHref(panelData.door))
        .attr('target', 'store_map');
    }

    // buttons to open empty search panel
    $('.js-link-open-finder', this.dom.$startPanel).on('click', function(e) {
      e.preventDefault();
      if (site.prodStoreCheck.enableAuoGeo) {
        $('body').addClass('ajax-wait');
        Model.DoorsInventory.load({
          skus: window.skuId,
          country: site.prodStoreCheck.country,
          region_id: that.region_id,
          language_id: that.language_id,
          useHeader: 1,
          callback: function(result) {
            if (result.count > 0) {
              that.handleOpenResultsClick();
              that.handleDoorsInvResp(result);
            } else {
              that.handleOpenFinderClickStore(result);
            }
          }
        });
      } else {
        that.handleOpenFinderClick();
      }
    });

    // button to open search panel with results
    $('.js-link-open-results', this.dom.$startPanel).on('click', function(e) {
      e.preventDefault();
      that.handleOpenResultsClick();
    });
  };

  /*
   * Handles event fired when the main panel (the container for the search panel)
   * is added to the DOM.
   * arguments Object optional contains an array of door objects
   * example { doors: [{DOOR_ID: 12345}]}
   */
  this.handleMainPanelRendered = function(args) {
    this.renderSearchPanel(args);
  };

  /*
   * tell Model to request doors & inventory
   */
  this.queryDoors = function(params) {
    var args = {
      skus: that.skuId,
      safetyStock: that.safetyStock,
      radius: 5,
      country: site.prodStoreCheck.country,
      region_id: that.region_id,
      language_id: that.language_id,
      useHeader: 1
    };

    if (params) {
      args = $.extend(args, params);
    }
    if (site.prodStoreCheck && site.prodStoreCheck.storeTypes) {
      args.inv_store_types = site.prodStoreCheck.storeTypes;
    }
    Model.DoorsInventory.load(args);
  };

  /*
   * Fetch DOM nodes and store as jQuery objects
   */
  this.getNodes = function() {
    this.dom.$app = $('.js-invis-app-container');
    this.dom.$closeLink = $('.js-close-link');
  };

  /*
   * Insert Invis templates into the DOM for fast retrieval
   * returns: Set of unprocessed templates wrapped in script.inline-template tags
   */
  this.initTemplates = function() {
    this.handleTemplatesInserted();
  };

  this.handleOpenResultsClick = function() {
    this.renderMainPanel({doors: that.doors});
  };
  this.handleOpenFinderClick = function() {
    that.resetInputValue();
    this.renderMainPanel();
  };
  this.handleOpenFinderClickStore = function(result) {
    this.renderMainPanel(result);
  };

  /*
   * Renders main panel, which is effectively an unbound search panel.
   * It puts the search panel into an overlay.
   */
  this.renderMainPanel = function(args) {
    var panelHtml = Mustache.render($('script[path="invis/search_panel"]').html(), {sku: this.inv_product});
    var onCompleteFn = function() {
      $(document).trigger('invis:mainPanel:rendered', args);
      this.populateCity();
    };
    this.openOverlay({
      content: panelHtml,
      onComplete: onCompleteFn
    });
    that.dom.$searchPanel = $('.js-invis-search-container');
    that.dom.$loadingIcon = $('.js-invis-spinner', that.dom.$searchPanel);
  };

  this.populateCity = function() {
    var citylocaldata = site.prodStoreCheck.cityLocalize.split(',');
    var $cityMenu = $('.js-invis-select-city', that.dom.$searchPanel);
    if ($cityMenu && citylocaldata.length > 1) {
      $cityMenu.empty();
      generic.forms.select.addOption({
        menuNode: $cityMenu,
        label: $cityMenu.attr('title') ? $cityMenu.attr('title') : '',
        value: ''
      });
      for (var i = 0; i < citylocaldata.length; i++) {
        generic.forms.select.addOption({
          menuNode: $cityMenu,
          value: citylocaldata[i].trim()
        });
      }
    }
    // set default city value
    $cityMenu.find('option[value="' + that.inv_vis_city + '"]').attr('selected', 'selected');

    $($cityMenu).selectBox();
  };

  this.showLoadingIcon = function() {
    $('body').addClass('ajax-wait');
    that.dom.$loadingIcon.removeClass('hidden');
  };

  this.hideLoadingIcon = function() {
    $('body').removeClass('ajax-wait');
    that.dom.$loadingIcon.addClass('hidden');
  };
  this.resetInputValue = function() {
    // Reset doors
    that.doors = [];
    that.count = 0;
    // reset default city - reset value when diff sku selected
    that.inv_vis_city = null;
  };

  /*
   * After search panel is insterted into DOM, wires up
   * data & events for the components.
   */
  this.renderSearchPanel = function(args) {
    this.bindLocationSearchForm();
    $('.js-invis-results', that.dom.$searchPanel).remove();
    if (args && args.count === 0) {
      that.renderMessages({errors: [{key: 'err_no_results'}]});
    }
    that.renderResults();
    $('.js-close-link', that.dom.$searchPanel).on('click', function(e) {
      e.preventDefault();
    });

    $(document).trigger('invis:searchPanel:rendered');
  };

  /*
   * Binds events to search form.
   */
  this.bindLocationSearchForm = function() {
    var $submit = $('.js-invis-search-submit', that.dom.$searchPanel);
    var $radius = $('.js-invis-select-radius', that.dom.$searchPanel);
    var $city = $('.js-invis-select-city', that.dom.$searchPanel);
    var defaultDistance = $radius.attr('data-default-distance') || 20;
    $radius.find('option[value=' + defaultDistance + ']').attr('selected', 'selected');
    var skuId = $('.js-invis-sku-container', that.dom.$searchPanel).data('sku-id');
    $submit.off('click').on('click', function() {
      that.showLoadingIcon();
      var radius = $radius.val();
      var city = $city.val();
      if (!city) {
        that.errors.push({ key: 'err_missing_city' });
        $(document).trigger('DoorsInventory:query:failure', {
          errors: that.errors
        });
      }
      that.queryDoors({
        skus: skuId,
        radius: radius,
        city: city,
        country: site.prodStoreCheck.country,
        safetyStock: that.safetyStock,
        useHeader: 0
      });
    });
  };

  /*
   * Handles event called after search panel is rendered.
   * Launches initial query to doorsandskustatus.
   */
  this.handleSearchPanelRendered = function() {
    $(this).colorbox.resize();
    $(window).resize(function() { /* Adding a window resize for Orientation changes for colorbox height and width  */
      $(this).colorbox.resize();
    });
  };

  /*
   * Fetches template for search results container, renders & inserts into DOM
   */
  this.renderResults = function() {
    var resultsHtml = Mustache.render($('script[path="invis/search/results"]').html());
    that.dom.$searchPanel.append(resultsHtml);
    if (that.count > 0) {
      if (!site.client.isMobile) {
        $(this).colorbox.resize();
      }
      $('.js-map-container', that.dom.$searchPanel).addClass('has-results');
    } else {
      $('.js-map-container', that.dom.$searchPanel).removeClass('has-results');
      if (!site.client.isMobile) {
        $(this).colorbox.resize();
      }
    }
    that.initMap();
  };

  this.renderMessages = function(args) {
    var panelData = {};
    _.each(args.errors, function(err) {
      switch (err.key) {
        case 'err_no_results':
          panelData.hasNoResults = 1;
          break;
        case 'err_missing_city':
          panelData.missingCity = 1;
          break;
        default:
          break;
      }
    });
    var msgsHtml = Mustache.render($('script[path="invis/search/messages"]').html(), panelData);
    var $oldResults = that.dom.$searchPanel.find('.js-invis-results');
    var $invisFormContainer = that.dom.$searchPanel;
    if ($oldResults.length) {
      $oldResults.remove();
    }
    that.resetInputValue();
    $invisFormContainer.append(msgsHtml);
    $(this).colorbox.resize();
  };

  this.openOverlay = function(args) {
    var _content = args.content;
    var _onComplete = args.onComplete;
    var _onClosed = args.onClosed;
    generic.overlay.launch({
      content: _content,
      includeBackground: false,
      includeCloseLink: true,
      initialHeight: 0,
      maxHeight: '100%',
      width: site.client.isMobile ? '100%' : '60%',
      opacity: '0.2',
      className: 'invis-overlay',
      onClosed: _onClosed,
      onComplete: function() {
        _onComplete();
        $(this).colorbox.resize();
      }
    });
  };

  this.initMap = function() {
    Model.MapApi.init('MapApi:init:complete');
  };

  this.getGoogleMapsHref = function(door) {
    var fields = ['ADDRESS', 'ADDRESS2', 'CITY', 'STATE_OR_PROVINCE', 'COUNTRY', 'ZIP_OR_POSTAL'];
    // adding a comma after door name to help Google parse query string into viable address
    var queryStr = door['DOORNAME'] ? door['DOORNAME'] + ',' : '';
    _.each(fields, function(f) {
      queryStr += door[f] ? door[f] + ' ' : '';
    });
    return 'http://maps.google.com/maps?q=' + encodeURIComponent(queryStr);
  };

  this.renderStores = function() {
    if (!that.doors || !that.doors.length) {
      return null;
    }
    var tableHtml = Mustache.render($('script[path="invis/search/doors_table"]').html());
    $('.js-stores-container', that.dom.$searchPanel).html(tableHtml);
    that.dom.$doorsTable = $('ul.js-results-list', that.dom.$searchPanel);

    var bounds = new google.maps.LatLngBounds();
    that.markers = [];
    _.each(that.doors, function(door, idx) {
      door.idx = idx;
      door.googleMapsHref = that.getGoogleMapsHref(door);
      var ltr = String.fromCharCode('a'.charCodeAt(0) + idx);
      door.markerSrc = '/sites/esteelauder/themes/estee_base/img/locator/marker_' + ltr + '_active.png';
      door['hasInventory'] = 0;
      door['hasAvailable'] = 0;
      door['isCall'] = 0;
      door['statusClass'] = 'no-inventory';
      if (door.skus_onhand && door.skus_onhand[that.skuId]) {
        var inv = door.skus_onhand[that.skuId];
        door['hasInventory'] = 1;
        door['statusClass'] = 'has-inventory';
        if (inv['is_available']) {
          door['statusClass'] = 'has-available';
          door['hasAvailable'] = 1;
        } else if (inv['is_call']) {
          door['isCall'] = 1;
          door['statusClass'] += ' call-for-availability';
        }
      }
      var doorHtml = Mustache.render($('script[path="invis/search/door"]').html(), door);

      // add store to results list
      that.dom.$doorsTable.append(doorHtml);

      // place marker on Google Map
      if (that.map) {
        var latLng = new google.maps.LatLng(door.LATITUDE, door.LONGITUDE);
        bounds.extend(latLng);
        var marker = new google.maps.Marker({
          position: latLng,
          map: that.map,
          icon: {
            url: door.markerSrc
          },
          index: idx,
          title: '' + door.DOOR_ID
        });
        that.map.fitBounds(bounds);

        marker.addListener('click', function() {
          that.handleStoreClick(marker.index);
        });

        that.markers.push(marker);
      }
    }); // end _.each

    // bind event to doors list items
    that.dom.$doorsTable.find('.js-door-list-item').on('click', function() {
      var i = $(this).data('door-idx');
      that.handleStoreClick(i);
    });

    that.handleSearchPanelRendered();
  }; // end renderStores

  this.handleStoreClick = function(i) {
    that.displayInfoWindow(i);
    that.selectDoorInList(i);
  };

  this.displayInfoWindow = function(i) {
    if (that.infoWindow) {
      that.infoWindow.close();
    }
    var door = that.doors[i];
    var marker = that.markers[i];
    var infoHtml = Mustache.render($('script[path="invis/search/door_info"]').html(), door);

    that.infoWindow = new google.maps.InfoWindow();
    that.infoWindow.setContent(infoHtml);
    that.infoWindow.open(that.map, marker);
    that.map.setCenter(marker.getPosition());
  };

  this.selectDoorInList = function(i) {
    var $list = that.dom.$doorsTable;
    $list.find('li').removeClass('selected');
    var $selectedLi = $list.find('li[data-door-idx=' + i + ']');
    if ($selectedLi.length) {
      $selectedLi.addClass('selected');
      $list.animate({
        scrollTop: $selectedLi.offset().top - $list.offset().top + $list.scrollTop()
      });
    }
  };

  this.renderMap = function() {
    var zoomLevel = 12;
    var centerValue = that.doors.length ? new google.maps.LatLng(that.doors[0].LATITUDE, that.doors[0].LONGITUDE) : null;
    var mapOptions = {
      zoom: zoomLevel,
      center: centerValue,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      disableDefaultUI: false,
      navigationControl: true,
      mapTypeControl: false,
      scaleControl: false
    };
    this.map = new google.maps.Map($('.js-map-container', that.dom.$searchPanel)[0], mapOptions);
    if (!that.doors.length) {
      var geocoder = new google.maps.Geocoder();
      var $country = site.prodStoreCheck.country || 'Hong Kong';
      geocoder.geocode({'address': $country}, function(results, status) {
        if (status === google.maps.GeocoderStatus.OK) {
          this.map.setCenter(results[0].geometry.location);
        }
      });
      $(this).colorbox.resize();
    }
  };

  /*
   * Doors are returned from the model sorted by distance. This function
   * searches through doors to find the closest door with available inventory.
   * If none is found, look for a door with  any inevntory. If neither is
   * found, return the closest door.
   */
  this.findBestDoor = function() {
    var door = null;
    door = _.find(that.doors, function(d) {
      return d.skus_onhand
          && d.skus_onhand[that.skuId]
          && d.skus_onhand[that.skuId]['is_available'];
    });
    if (!door) {
      door = _.find(that.doors, function(d) {
        return d.skus_onhand
            && d.skus_onhand[that.skuId];
      });
    }
    if (!door) {
      door = that.doors[0];
    }
    return door;
  };

  return that;
})();
